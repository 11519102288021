/**
 * module_value_setter.js
 * 初期値の設定
 */

/* 共通関数プラグイン */
var common = require('lib/common_lib.js');

$(function() {
  // 今年を設定
  if($(".set-current-year").length > 0) {
    var today = new Date();
    var year = today.getFullYear();
    $(".set-current-year").val(year);
  }

  // 今月を設定
  if($(".set-current-month").length > 0) {
    var today = new Date();
    var month = today.getMonth() + 1;
    $(".set-current-month").val(month);
  }

});
