$(function() {
  $('.text-overflow').each(function() {
    var count = 20;
    if ($(this).attr('data-overflow') != undefined) {
      count = Number($(this).data("overflow"));
    }
    var thisText = $(this).text().trim();
    var textLength = thisText.length;
    if (textLength > count) {
      var showText = thisText.substring(0, count - 1);
      var hideText = thisText.substring(count - 1, textLength);
      var insertText = showText;
      insertText += '<span class="hide">' + hideText + '</span>';
      insertText += '<span class="omit">…</span>';
      insertText += '<a href="" class="more txt-link">もっと見る</a>';
      insertText += '<a href="" class="close txt-link">閉じる</a>';
      $(this).html(insertText);
    };
  });
  $('.text-overflow .hide').hide();
  $('.text-overflow .close').hide();

  $('.text-overflow .more').click(function() {
    $(this).hide()
      .prev('.omit').hide()
      .prev('.hide').fadeIn();
    $(this).closest(".text-overflow").find(".close").show();
    return false;
  });
  $('.text-overflow .close').click(function() {
    $(this).hide()
      .prev('.more').show()
      .prev('.omit').show()
      .prev('.hide').hide();
    return false;
  });
});


$(function() {
  $('.text-overflow-cut').each(function() {
    var count = 20;
    if ($(this).attr('data-overflow') != undefined) {
      count = Number($(this).data("overflow"));
    }
    var thisText = $(this).text();
    var textLength = thisText.length;
    if (textLength > count) {

      var showText = thisText.substring(0, count - 1);
      var insertText = showText;
      insertText += '<span class="omit">…</span>';
      $(this).html(insertText);

      $(this).addClass("hover-disp");
      var all_text = thisText;

      $(this).append("<p class='table-float'>" + all_text + "</p>");

      var table_left = $(this).parents("table").offset().left;
      var left = $(this).offset().left;
      var table_top = $(this).parents("table").offset().top;
      var top = $(this).offset().top;

      var h = $(this).find(".table-float").outerHeight();

      $(this).find(".table-float").css("left", left - table_left);
      // $(this).find(".table-float").css("top", top - table_top - h);
      $(this).find(".table-float").css("top", 0);

    };
  });
});

$(function() {
  $('.news-text-overflow-cut').each(function() {
    var count = 20;
    if ($(this).attr('data-overflow') != undefined) {
      count = Number($(this).data("overflow"));
    }
    var thisText = $(this).text();
    var textLength = thisText.length;
    if (textLength > count) {

      var showText = thisText.substring(0, count - 1);
      var insertText = showText;
      insertText += '<span class="omit">…</span>';
      $(this).html(insertText);

      $(this).addClass("hover-disp");
      var all_text = thisText;

      $(this).append("<p class='table-float'>" + all_text + "</p>");

      var table_left = $(this).parents("table").offset().left;
      var left = $(this).offset().left;
      var table_top = $(this).parents("table").offset().top;
      var top = $(this).offset().top;

      var h = $(this).find(".table-float").outerHeight();

      $(this).find(".table-float").css("left", left - table_left);
      // $(this).find(".table-float").css("top", top - table_top - h);
      $(this).find(".table-float").css("top", 0);

    };
  });
});
