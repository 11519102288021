/**
 * module_scroll_fadein.js
 * スクロールアニメーション（フェードイン）の設定
 */


// アニメーション判定（起動時）
$(function() {
  $value = $(this).scrollTop(); //スクロール値を取得
  $b_height_herf = $(window).height() / 2;
  $b_height = $(window).height();
  $display_y = $value + $b_height_herf;

  // スクロールアニメーション
  $(".js-scroll-target").each(function() {
    if (isAnimationInitial($(this))) {
      $(this).addClass("is--animated");
      $(this).addClass("fin");
    }
  });

  /* スクロールトリガー */
  $(window).scroll(function() {
    $value = $(this).scrollTop(); //スクロール値を取得
    $b_height = $(window).height() / 2;
    $display_y = $value + $b_height;

    // スクロール
    $(".js-scroll-target").not(".js-scroll-target.fin").each(function() {
      if (isAnimation($(this))) {
        $(this).addClass("is--animated");
        $(this).addClass("fin");
      }
    });
  });
});

/**
 * イニシャルアニメーション判定
 */
function isAnimationInitial($target) {

  $ret = true;

  $value = $(window).scrollTop(); //スクロール値を取得
  $b_height = $(window).height();

  $height = $target.innerHeight();
  $height_herf = $target.innerHeight() / 2;
  $target_t = $target.offset().top; /* ターゲットのTOP座標 */
  $target_m = $target_t + $height_herf; /* ターゲットの中央座標 */
  $target_b = $target_t + $height; /* ターゲットのBOTTOM座標 */

  if (($target_b >= $value && $value + $b_height >= $target_b) ||
    ($value >= $target_t && $target_b >= $value) ||
    ($target_t >= $value && $value + $b_height >= $target_t)) {
    $ret = true;
  } else {
    $ret = false;
  }

  return $ret;
}

/**
 * スクロール時アニメーション判定
 */
function isAnimation($target) {

  $ret = true;

  $value = $(window).scrollTop(); //スクロール値を取得
  $b_height = $(window).height();

  $height = $target.innerHeight();
  $height_herf = $target.innerHeight() / 2;
  $target_t = $target.offset().top; /* ターゲットのTOP座標 */
  $target_m = $target_t + $height_herf; /* ターゲットの中央座標 */
  $target_b = $target_t + $height; /* ターゲットのBOTTOM座標 */

  if (($target_b >= $value && $value + $b_height >= $target_b) ||
    ($target_m >= $value && $value + $b_height >= $target_m) ||
    ($target_t >= $value && $value + $b_height >= $target_t)) {
    $ret = true;
  } else {
    $ret = false;
  }

  return $ret;
}
