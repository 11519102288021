/**
 * module_wp_members.js
 * wp membersの設定
 */

$(function() {

  // ---------------------------------------
  // medical/input.php メディックスゲート登録
  // ---------------------------------------
  $(document).on("click", "#js-regist-no", function() {
    history.back();
  });

  $(document).on("click", "#js-regist-yes", function() {

    var ret = validationStart();
    if(!ret) {
      return false;
    }

    var password = $('.js-password').val();
    var confirm_password = $('.js-confirm-password').val();

    // wp-membersのformに連動
    $('#password').val(password);
    $('#confirm_password').val(confirm_password);

    $('#wpmem_register_form').attr('action', '/');
    $('input[name="submit"]').trigger('click');
  });

  // ---------------------------------------
  // medical/index.php ログイン
  // ---------------------------------------
  $(document).on("click", "#js-signin", function() {

    // フォームのデータ取得
    var username = $('.js-username').val();
    var password = $('.js-password').val();

    // wp-membersのformに連動
    $('.username').val(username);
    $('#pwd').val(password);

    // POSTフラグ
    $('#wpmem_login_form').append('<input type="hidden" name="post_flg" value="1" />');

    // 保存チェックボックスの連動
    if ($('.js-keep-flg').prop('checked') == true) {
      $('#rememberme').prop('checked', true);
    }

    // reCaptcha
    $('#wpmem_login_form').append('<input type="hidden" name="recaptcha_token" value="" id="recaptcha_token" />');
    var recaptcha_token = $('#recaptcha_tmp').val();
    $('#recaptcha_token').val(recaptcha_token);

    // $('#wpmem_login_form').attr('action', '/');
    $('#wpmem_login_form').submit();
  });

  // ---------------------------------------
  // パスワードリセット
  // ---------------------------------------
  $(document).on("click", "#js-password-mail", function() {

    var ret = validationStart();
    if(!ret) {
      return false;
    }

    // フォームのデータ取得
    var mail = $('.js-mail').val();

    $('#user').val(mail);
    $('#email').val(mail);

    if (confirm('メールアドレスに間違いはないですか？')) {
      // POSTフラグ
      $('#wpmem_pwdreset_form').append('<input type="hidden" name="pw_rest_post_flg" value="1" />');
      $('#wpmem_pwdreset_form').attr('action', '/');

      $('#wpmem_pwdreset_form').submit();
    }
  });

  // ---------------------------------------
  // パスワード再設定
  // ---------------------------------------
  $(document).on("click", "#password-reset-main #js-btn", function() {

    var ret = validationStart();
    if(!ret) {
      return false;
    }

    // フォームのデータ取得
    var pw = $('[name=password]').val();
    var pw_c = $('[name=confirm_password]').val();

    $('#pass1').val(pw);
    $('#pass2').val(pw_c);

    if (confirm('パスワードに間違いはないですか？')) {
      // POSTフラグ
      $('#wpmem_pwdchange_form').append('<input type="hidden" name="pw_rest_post_flg" value="2" />');
      $('#wpmem_pwdchange_form').attr('action', '/');

      $('#wpmem_pwdchange_form').submit();
    }
  });


  // ---------------------------------------
  // 退会
  // ---------------------------------------
  $(document).on("click", "#withdrawal-main #js-btn", function() {

    var ret = validationStart();
    if(!ret) {
      return false;
    }

    // フォームのデータ取得
    var pw = $('[name=password]').val();

    $('#plugin_delete_me_shortcode_password').val(pw);

    if (confirm('本当に退会してよろしいでしょうか？')) {
      $('#plugin_delete_me_shortcode_form').submit();
    }
  });


  // ---------------------------------------
  // 会員情報変更
  // ---------------------------------------
  $(document).on("click", "#update-main #js-btn", function() {

    // フォームのデータ取得
    var last_name = $('[name=tmp_last_name]').val();
    var first_name = $('[name=tmp_first_name]').val();
    var last_name_kana = $('[name=tmp_last_name_kana]').val();
    var first_name_kana = $('[name=tmp_first_name_kana]').val();
    var pref = $('[name=tmp_pref]').val();
    var work_facility_name = $('[name=tmp_work_facility_name]').val();
    var classification = $('[name=tmp_classification]').val();
    var department_category = $('[name=tmp_department_category]').val();

    $("[name=last_name]").val(last_name);
    $("[name=first_name]").val(first_name);
    $("[name=last_name_kana]").val(last_name_kana);
    $("[name=first_name_kana]").val(first_name_kana);
    $("[name=pref]").val(pref);
    $("[name=work_facility_name]").val(work_facility_name);
    $("[name=classification]").val(classification);
    $("[name=department_category]").val(department_category);

    $('#wpmem_profile_form').append('<input type="hidden" name="pw_rest_post_flg" value="3" />');

    $('#wpmem_profile_form').attr('action', '/');
    $('input[name="submit"]').trigger('click');
  });

  // ---------------------------------------
  // company/guidelines/input.php 透明性ガイドライン登録「はい」
  // ---------------------------------------
  $(document).on("click", ".js-confirm-yes", function() {


    var ret = validationStart();
    if(!ret) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    $('.common__form').submit();
  });

  // ---------------------------------------
  // company/guidelines/confirm.php 透明性ガイドライン登録「はい」
  // ---------------------------------------
  $(document).on("click", "#guideline-confirm-yes", function() {

    $('#wpmem_register_form').attr('action', '/wp_guideline/');
    $('.button_div input[name="submit"]').trigger('click');
  });

  // ---------------------------------------
  // 透明性ガイドラインのログイン
  // ---------------------------------------
  $(document).on("click", "#wp-login-button", function() {
    // フォームのデータ取得
    var username = $('.js-username').val();
    var password = $('.js-password').val();

    // wp-membersのformに連動
    $('.username').val(username);
    $('#pwd').val(password);

    // POSTフラグ
    $('#wpmem_login_form').append('<input type="hidden" name="post_flg" value="1" />');

    // 保存チェックボックスの連動
    if ($('.js-keep-flg').prop('checked') == true) {
      $('#rememberme').prop('checked', true);
    }

    // reCaptcha
    $('#wpmem_login_form').append('<input type="hidden" name="recaptcha_token" value="" id="recaptcha_token" />');
    var recaptcha_token = $('#recaptcha_tmp').val();
    $('#recaptcha_token').val(recaptcha_token);

    $('#wpmem_login_form').submit();
  });

  // パスワード再発行時
  // $('#wpmem_pwdreset_form').find('label').text('メールアドレスを入力してください');
  // $('#wpmem_pwdreset_form').find('.buttons').val('送信する');
  // $('#wpmem_pwdreset_form').find('.buttons').on('click', function() {
  //   // $('#wpmem_pwdreset_form').attr('action', '/');
  // });

  // パスワード変更時
  // $('#wpmem_pwdchange_form').find('.buttons').on('click', function() {
  //   $('#wpmem_pwdchange_form').attr('action', '/');
  // });

  // 必須チェック
  var form_required = function() {
    var label = "";
    var message = "";
    var message_arr = [];
    $('.form-required').each(function(index){
      if(!$(this).eq(index).val()) {
        label = $(this).eq(index).attr('placeholder');
        message = label + 'は入力必須項目です。';
        message_arr.push(message);
      }
    });
    return message_arr;
  }

  // メールアドレスの形式チェック
  var mail_check = function() {
    var mail = $('input[name="user_email"]').val();
    var reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
    if (reg.test(mail)) {
      console.log('メールアドレスが正しいです。');
      return true;
    } else {
      alert('メールアドレスが間違っています。');
      return false;
    }
  }

  var confirm_mail_check = function() {
    var mail = $('input[name="user_email"]').val();
    var confirm_mail = $('input[name="confirm_user_email"]').val();
    var message;
    if (mail == confirm_mail) {
      return true;
    } else {
      alert("メールアドレスが一致していません。");
      return false;
    }
  }

  var confirm_password_check = function() {
    var password = $('input[name="password"]').val();
    var confirm_password = $('input[name="confirm_password"]').val();
    var message;
    if (password == confirm_password) {
      return true;
    } else {
      alert("パスワードが一致していません。");
      return false;
    }
  }

});
