/**
 * module_slider.js
 * splide.jsの設定
 */

import Splide from '@splidejs/splide';
// import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

let splide_class="";

splide_class = ".kv__slider";
if($(splide_class).length > 0) {
  new Splide(splide_class, {
    type   : 'loop',
    autoplay: true,
    interval: 5000,
    pauseOnHover: false,
  }).mount();
}

splide_class = ".top__ban__slider";
if($(splide_class).length > 0) {
  new Splide(splide_class, {
    type   : 'loop',
    perPage: 4,
    focus  : 'center',
    autoWidth: true,
    padding: '1px',
    autoScroll: {
      speed: 2,
    },
  }).mount( window.splide.Extensions );
}

splide_class = ".event__year";
if($(splide_class).length > 0) {

  var i = 0;
  var tar_no = 0;
  $(".event__year .splide__slide").each(function() {
      if($(this).find(".link").hasClass("current")) {
        tar_no = i;
        new Splide(splide_class, {
          autoplay: false,
          perPage: 4,
          gap: "20px",
          pagination:false,
          start:tar_no,
          breakpoints: {
            767: {
              perPage: 2,
            },
          }

        }).mount();

        return false;
      }
      i++;
  });
}
