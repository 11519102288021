/**
 * module_contact.js
 * お問い合わせ
 */

$(function() {
  if( $('#commit').length ){
    $('#commit').on("click", function(e){
      $('.commit').val('1');
    });
  }
});
