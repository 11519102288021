// ボタンの位置置き換え
$(function(){
  // if (window.matchMedia("(max-width: 767px)").matches) {
  //
  //   if ($('.js-btn-change').length) {
  //     $('.common__btn__list').html(
  //       $('.common__btn__list li').sort(function(a, b) {
  //         return ($(a) > $(b) ? 1 : -1);
  //       })
  //     );
  //
  //     $('.common__btn__list').addClass("changed");
  //   }
  // }

  $(window).resize(function() {

    if ($(window).width() < 768) {
      if ($('.js-btn-change').length && !$('.common__btn__list').hasClass("changed")) {
        $('.common__btn__list').html(
          $('.common__btn__list li').sort(function(a, b) {
            return ($(a) > $(b) ? 1 : -1);
          })
        );

        $('.common__btn__list').addClass("changed");
      }
    }

    if ($(window).width() < 768) {
      if ($('.js-btn-change').length && !$('.common__btn__list').hasClass("changed")) {
        $('.common__btn__list').html(
          $('.common__btn__list li').sort(function(a, b) {
            return ($(a) > $(b) ? 1 : -1);
          })
        );

        $('.common__btn__list').addClass("changed");
      }
    } else {
      if ($('.js-btn-change').length && $('.common__btn__list').hasClass("changed")) {
        $('.common__btn__list').html(
          $('.common__btn__list li').sort(function(a, b) {
            return ($(a) > $(b) ? 1 : -1);
          })
        );

        $('.common__btn__list').removeClass("changed");
      }
    }


  }).resize();
});
