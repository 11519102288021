/**
 * components.js
 * js結合ファイル
 */

'use strict';

/* nodeモジュール */
import $ from 'jquery';
window.$ = $;
window.jQuery = $;

/* 独自モジュール */
require('module_smooth_scroll.js');       // スムーズスクロール
require('module_same_height.js');         // ブロックの高さをそろえる
require('module_modal.js');               // モーダル
require('module_control.js');             // 表示制御機能
require('module_control_disabled.js');    // 表示制御機能
// require('module_tippy.js');               // ツールチップ
require('module_tab_cont.js');           // タブ切り替え機能
require('module_g-nav.js');               // グローバルナビ
// require('module_drop_file.js');           // ドラッグアンドドロップ
// require('module_ui.js');                  // Jquery-ui
// require('module_float_head.js');           // テーブルヘッダー固定
// require('module_calendar.js');           // カレンダー表示
// require('module_slick.js');              // スライダー
require('module_text_more.js');          // テキスト制限
require('module_value_setter.js');       // 値の設定
require('module_scroll_fadein.js');     // スクロールアニメーション（フェードイン）
require('module_wp_members.js');         // wp members のフォーム
require('module_img_modal.js');     // 画像拡大モーダル
require('module_btn_change.js');     // ボタン並び替え
require('module_pri_load.js');     // ボタン並び替え
require('module_slider.js');     // スライダー
require('module_contact.js');     // お問い合わせ
// require('lib/jquery.jpostal.js');   // 郵便番号
require('module_event_gralley.js');     // イベントギャラリー


//require('module_no_back.js');               // ブラウザバック禁止
